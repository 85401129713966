<template>
	<div>
		<v-container>
			<v-row style="margin-bottom: -35px">
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>Muestreo de materia prima</v-toolbar-title>
					</v-toolbar>
					<v-card style="padding-bottom: 40px">
						<v-container>
							<s-scanner-qr
								:config="configScann"
								:autofocus="false"
								return-object
								@onValue="onValue($event)"
								:readonly="false"
							></s-scanner-qr>
							<v-row align="center" justify="center">
								<b>{{ info }}</b>
							</v-row>
						</v-container>
					</v-card>
				</div>
			</v-row>
			<v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"
						edit
						remove
						@save="save($event)"
						searchInput
						@rowSelected="rowSelected($event)"
						title="Listado de pallets muestreados"
						no-full
						height="auto"
						dense
						ref="gridWeihingSampling"
					>
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12" >
										<s-select-definition
										autocomplete
										label="Turno"
										v-model ="filter.TypeTurn"
										:dgr="54"
										:def="1062"
										clearable
										></s-select-definition>
									</v-col>
									<v-col>
										<s-select 
											:items="StatePallet"
											label="Estado Proceso"
											item-value="StsID"
                                    		item-text="StsDescription"
											v-model="filter.ItemStatePallet"											
											clearable>

										</s-select>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>

						
						<template v-slot:details="{ row }">
												
							<v-btn
								
								x-small
								:color="'error'"
								fab
								style="margin: 6px 6px 6px 6px;"
								@click="deleteParihuela(row)">
								<v-icon
										style="font-size: 16px !important">
										fas fa-times</v-icon>
							</v-btn>

							<v-btn							
								color="info"
								x-small
								fab
								@click="openEditSampling(row)"
							>
								<v-icon medium>fa-thin fa-pen</v-icon>
							</v-btn>
									
						</template>

						<template v-slot:MtpStatus="{ row }">
							<v-chip
								x-small
								:color="
									row.MtpStatus == 1 ? 'success' : 'primary'
								"
							>
								{{
									row.MtpStatus == 1 ? "No-Maduro" : "Maduro"
								}}
							</v-chip>
						</template>
						
						<template v-slot:SendProceess="{ row }">
							<v-chip style="margin:0px" x-small :color="row.SendProceess == 2 ? 'success' : 'warning'">
								{{ row.SendProceess == 2 ? "Procesado" :  "Sin Procesar"}}
						</v-chip>
					</template>
					</s-crud>
				</v-col>
			</v-row>
		</v-container>

		<!-- ventana modal de pesos  -->
		<v-dialog
			v-model="dialogWeigh"
			v-if="dialogWeigh"
			width="500"
			hide-overlay
			persistent
			transition="dialog-bottom-transition"
		>
		<!--persistent-->
			<!-- <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="2" /> -->
			<v-card class="m-0 p-0">
				<s-toolbar
					save
					@save="save()"
					label="Control de Pesado"
					dark
					:color="'#BAB6B5'"
					close
					@close="closeWeighed()"
					class="mb-6"
				></s-toolbar>			
				<v-container>
					<v-row justify="center">
						<v-col cols="12">
							<s-text
								v-model="itemsampling.MtpWeight"
								label="Ingrese el peso del pallet"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
		

		<!-- modal para saldos -->
		<v-dialog v-model="dialogBalance" persistent v-if="dialogBalance">
			<v-card>
				<pallet-balance
					@CloseBalance="CloseBalance"
					:pallet="itemsampling"
				></pallet-balance>
			</v-card>
		</v-dialog>

		<!-- modal para detalles del pallet  -->
		<v-dialog
			persistent
			v-model="showPalletDetail"
			transition="dialog-bottom-transition"
			width="80%"
			v-if="showPalletDetail"
		>
			<v-card class="elevation-0">
				<!--  <s-toolbar
          dense
          dark
          upper
          label="Todas las muestras del pallet"
          @close="showPalletDetail = false"
          close>
        </s-toolbar> -->
				<sample-pallet-details
					:dataPallet="itemsampling"
					@close="showPalletDetail = false">
				</sample-pallet-details>
			</v-card>
		</v-dialog>

		<!-- modal del muestreo  v-->
		<v-dialog
			persistent
			v-model="openModalBrix"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			v-if="openModalBrix"
		>
			<v-card class="elevation-0">
				<sumpling-frozen
					:LlpID ="LlpID"
					:items="itemsampling"
					@close="closeModalBrix()"
					:isSamplingEdit="isSamplingEditG"
				></sumpling-frozen>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="editSampling"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			v-if="editSampling"
			persistent
			>
			<v-card class="elevation-0">
				<sumpling-frozen
				:isEdit="editSampling"
				:items="itemEditsampling"
				@close="editSampling = false"
				:isSamplingEdit="isSamplingEditG"
				edit
				></sumpling-frozen>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

	import _sMaturationTunnelPalletService from "../../../services/FrozenProduction/MaturationTunnelPalletService.js"; // usando

	import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling.js";

	import SScannerQr from "@/components/Utils/sScannerQr.vue";

	import _sReceptionStorageService from "../../../services/Technicalassistance/ReceptionStorageService";
	
	import WeighedByBatch from "@/views/FreshProduction/RawMaterialReception/WeighedByBatch";
	import SumplingFrozen from "@/views/FrozenProduction/ZoomSelection/SumplingFrozen";
	import FrzWeighedByBatch from "@/views/FrozenProduction/FrzWeighedByBatch/FrzWeighedByBatch";

	// nuevo pesado
	import FrzWeighedByPallet from "@/views/FrozenProduction/FrzMaduration/FrzWeighedByPallet";

	import PalletBalance from "@/views/FrozenProduction/WashDisinfect/PalletBalance";
	import SamplePalletDetails from "./SamplePalletDetails.vue";

	

	export default {
		components: {
			WeighedByBatch,
			SumplingFrozen,
			SScannerQr,
			FrzWeighedByBatch,
			FrzWeighedByPallet,
			PalletBalance,
			SamplePalletDetails,
		},
		props: {
			dataPallet: null,
		},
		data: () => ({
			LlpID: 0,
			dialogBalance: false,
			itemsampling: {},
			info: "",
			itemsDetails: [],
			showPalletDetail: false,
			configScann: {},
			dialogWeigh: false,
			weight: 0,
			openModalBrix: false,
			MtpID: 0,
			ship: 2,
			
			isSamplingEditG : false,
			editSampling : false,
			itemEditsampling : {},
			filter: {
				DateBegin: null,
				MtpDateEnd: null,
				MtpStatus: 2,
				sampling: 1,
				TypeTurn: 0,
				ItemStatePallet: 0
			},
			config: {
				model: {
					LlpID: "ID",
					DateBegin: "date",
					MtpDateEnd: "date",
					details: "",
					MtpStatus: "",
					SendProceess: "",
				},
				service: _sFrozenSampling,
				headers: [					
					{ text: "Detalle", value: "details", width: 20 },					
					{ text: "ID Pallet sunexpert", value: "LlpID", width: 20 },
					{ text: "N° Pallet", value: "LlpCorrelative", width: 20 },
					{ text: "Fecha", value: "DateSampling", width: 60 },
					{ text: "Hora", value: "HourSampling", width: 60 },
					{ text: "Cliente", value: "CumNombre", width: 60 },
					{ text: "Turno", value: "DedDescription", width: 60 },
					{ text: "Color", value: "ModaColor", width: 60 },
					{ text: "Firmeza", value: "ProFirmnessFrozen", width: 60 },
					{ text: "Brix", value: "BrixFrozen", width: 60 },	
					{ text: "Estado Procesado", value: "SendProceess", width: 60 },					
				],
			},StatePallet: [
				{ StsID: 1, StsDescription: "Sin Procesar"},
				{ StsID: 2, StsDescription: "Procesado"},
			],
			SelectItemStatePallet: 0,
		}),
		watch: {

			

		},

		methods: {
			
			deleteParihuela(val){

				
				val.SecStatus = 0;
				val.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert("Esta de seguro de eliminar Muestreo de materia prima ?", "question")
					.then(res => {
						if (res.value) {
							
							_sFrozenSampling
								.send(val, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Pallet elimiado con exito",
											"success",
										);
										this.$refs.gridWeihingSampling.refresh();
										
									}
								});
						}
					});
			},

			openEditSampling(item){
				this.isSamplingEditG = true;
				this.editSampling = true;

				this.itemEditsampling = item;
				// this.itemEditsampling.TypeCultive = this.dataPallet.TypeCultive;
			},

			closeModalBrix() {
				this.openModalBrix = false;
				this.$refs.gridWeihingSampling.refresh();
			},

			onValue(val) {
				
				if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
					this.LlpID = val;
					
					_sMaturationTunnelPalletService.getMtp(val, this.$fun.getUserID()).then((r) => {
							if (r.status == 200) {

								this.MtpID = r.data.MtpID;
								//this.isSamplingEditG = false;
								this.openModalBrix = true;
								this.itemsampling = r.data;							
								
							}
						});
				}
			},


			ModalBalance() {
				this.dialogBalance = true;
			},

			CloseBalance() {
				this.dialogBalance = false;
			},

			saveWeight() {
				
				_sMaturationTunnelPalletService
					.save(this.itemsampling, this.$fun.getUserID())
					.then((r) => {
						if (r.status == 200) {
							this.$fun.alert("Peso guardado correctamente");
							this.dialogWeigh = false;
							//this.itemsampling = {};
						}
					});
			},

			rowSelected(item) {
				if (item.length > 0) {
					
					_sReceptionStorageService
						.GetDetails(
							{ PseID: item[0].PseID, PseState: 1 },
							this.$fun.getUserID()
						)
						.then((r) => {
							this.itemsDetails = r.data;
						});
				}

				//this.itemsampling = item[0];
			},

			isValidatedItems(val) {
				var isValidated = true;

				if (val.RseNumberGuide.length == 0 || val.RseNumberGuide == null) {
					this.$fun.alert("Ingrese Guia remision", "warning");
					isValidated = false;
					return;
				}

				if (
					val.RseNumberGuideCarrier.length == 0 ||
					val.RseNumberGuideCarrier == null
				) {
					this.$fun.alert(
						"Ingrese guia remision transportista",
						"warning"
					);
					isValidated = false;
					return;
				}

				this.itemsDetails.forEach((element) => {
					if (element.RsdQuantityReceived <= 0) {
						this.$fun.alert(
							"Cantidad recibida deber ser mayor a 0",
							"warning"
						);
						isValidated = false;
						return;
					}

					if (element.RsdQuantityReceived == "") {
						this.$fun.alert(
							"Cantidad recibida deber ser mayor a 0",
							"warning"
						);
						isValidated = false;
						return;
					}
				});

				return isValidated;
			},

			WeighMaterial() {
				this.dialogWeigh = true;
			},
			ModalBrix() { 
				this.openModalBrix = true;
			},

			closeWeighed() {
				this.dialogWeigh = false;
				this.$refs.gridWeihingSampling.refresh();
			},

			openDetailPallet(val) {
				
				this.itemsampling = val;
				this.showPalletDetail = true;
			},
		},
	};
</script>

<style></style>
