<template>
  <div>
    <!-- <v-toolbar class="elevation-0" dark color="primary" height="40">     
      <h2>MUESTREO</h2>
      <v-spacer></v-spacer>

      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar> -->
    <!-- save @save="save()" -->
    <s-toolbar dense dark color="#7b7b7b" close save @save="save()" @close="$emit('close')" :label="this.items.LlpID > 0 ? 'Muestreo del Pallet ' + this.items.LlpID : ' '"> 
      <div style="display: flex; align-items: center;">
        <s-text v-model="searchLlpID">
          
        </s-text>
        <v-btn text 
            x-small 
            fab 
            @click="searchSamplingFrosen()"
                ><v-icon >mdi-magnify</v-icon>
        </v-btn>
        
      </div>
    </s-toolbar>

    <v-col>
      <!-- <v-card class="pt-0 elevation-0 pb-0" elevation="0" :disabled="isEdit"> -->
      <v-card class="pt-0 elevation-0 pb-0" elevation="0">

        <!-- datos de la cabecera -->
        <v-row>
          <v-col cols="6" :lg="isTunnelMaduration ? 3 : 4" 
             :md="isTunnelMaduration ? 3 : 4" 
             :sm="isTunnelMaduration ? 12 : 6"  class="s-col-form">
            <s-date v-model="itemHead.FfsDateSampling" label="Fecha"></s-date>
          </v-col>
          <v-col cols="6" :lg="isTunnelMaduration ? 3 : 4" :md="isTunnelMaduration ? 3 : 4" 
          :sm="isTunnelMaduration ? 12 : 6"
          class="s-col-form">
            <!--<s-time
              v-model="itemHead.FfsHour"
              label="Hora de muestreo"
            ></s-time>-->

            <!-- <s-text
              label="Hora de muestreo"
              v-model="itemHead.FfsHour"
              type="time"
            ></s-text> -->

            <label slot="before">Hora de muestreo</label>
            <div class="relojito">
              <datetime valueZone="America/Lima"  title="Hora  :  Minutos"  type="time" v-model="itemHead.FfsHour">
              </datetime>
            </div>
          </v-col>
          <v-col v-if="isTunnelMaduration" cols="12" lg="3" md="3" class="s-col-form">
            <Sselect-client-sap itemtext="itemHead.CardName" v-model="itemHead.FfsCliente" label="Cliente">
            </Sselect-client-sap>
          </v-col>
          <v-col cols="6" :lg="isTunnelMaduration ? 3 : 4" :md="isTunnelMaduration ? 3 : 4"
            :sm="isTunnelMaduration ? 12 : 6"
           class="s-col-form">
            <s-select-definition
              label="Punto de muestreo"
              :def="1334"
              v-model="itemHead.FfsPointSampling"
            >
            </s-select-definition>
          </v-col>
          <v-col cols="6" lg="4" md="4"  class="s-col-form">
            <s-load v-model="photoSampling" label="Imagen de muestreo"></s-load>
          </v-col>

          <v-col cols="6" lg="4" md="4"  class="s-col-form">
            <s-text
              v-model="itemHead.FfSsampledUnits"
              ref="FocuesFfSsampledUnits"
              label="Unidades Muestreadas"
              readonly
            ></s-text>
          </v-col>
          <v-col cols="2" lg="2" md="2"  class="s-col-form"
            ><s-select-definition
              label="Unidad de Muestreo"
              v-model="itemHead.FsfUnitMeasurement"
              :def="1286"
            ></s-select-definition
          ></v-col>
          <!-- <v-col v-if="cultiveType == 3" cols="2" lg="2" md="2"  class="s-col-form">
            <s-text  decimal label="Peso Grs (< 100-119)" v-model="itemHead.FfsFatWeightMax"></s-text>
          </v-col>
          <v-col v-if="cultiveType == 3" cols="2" lg="2" md="2"  class="s-col-form">
            <s-text decimal label="Peso Grs (>200)" v-model="itemHead.FfsFatWeightMin"></s-text>
          </v-col> -->
          <v-col cols="2" lg="2" md="2"  class="s-col-form">
            <s-select
              :items="itemsDataVariety"
              v-model="objVarietyBRIX"
              item-text="VrtName"
              item-value="VrtID"
              return-object
              label="Variedad"
              ref="colorSelect"
              @input="inputCaliber($event)"
            ></s-select>
            <!-- <s-select-definition
              label="Unidad de Muestreo"
              v-model="itemHead.FsfUnitMeasurement"
              :def="1286"
            ></s-select-definition
            > -->
          </v-col>
          <v-col cols="6" lg="4" md="4"  class="s-col-form">
            <s-select
              ref="CliMadSelect"
              label="Clientes de Maduración"
              :items="listCustomer"
              v-model="itemHead.CumId"
              item-value="CumId"
              item-text="CumNombre"              
              clearable
            ></s-select>
          </v-col>
          <!-- v-model="objCustMaturation" -->
          <v-col cols="6" lg="4" md="4"  class="s-col-form">
            <s-select-definition
              autocomplete
              label="Turno"
              v-model ="itemHead.TypeTurn"
              :dgr="54"
              :def="1062"
            ></s-select-definition>
          </v-col>
          
          <v-col cols="12" lg="12" md="12" sm="12" class="s-col-form">
            <s-textarea
              v-model="itemHead.FfsObservations"
              label="Observaciones"
              outlined
              :length="10"
              rows="1 "
              row-height="15"
            ></s-textarea>
          </v-col>
        </v-row>
        <!-- fin de datos de la cabecera -->
        <v-row>
          <v-col class="pt-0 pb-3"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="6" lg="4" md="4" class="pt-0">
            <!-- MUESTREO DE BRIX -->
            <v-row v-if="cultiveType != 3" class="pt-3 pr-3 pl-3">
              <h3>BRIX</h3>
              <v-spacer></v-spacer>
              <v-btn rounded color="" fab x-small @click="addBrix()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-col v-if="cultiveType != 3">
              <v-row>
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select
                    :items="itemsDataVariety"
                    v-model="objVarietyBRIX"
                    item-text="VrtName"
                    item-value="VrtID"
                    return-object
                    label="Variedad"
                    ref="colorSelect"
                    @input="inputCaliber($event)"
                  ></s-select> 
                </v-col> -->
                <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  <s-text
                    v-model="FfsBrixValue"
                    ref="focusBrixValue"
                    label="Valor Brix"
                    decimal
                    @keyupEnter="addBrix()"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-0 pt-0">
                  <v-data-table
                    dense
                    :headers="headersBrix"
                    :items="itemsDataBrix"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteBrix(item)"
                        x-small
                        block
                        rounded
                        color="error"
                      >
                        <v-icon style="font-size: 16px !important"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <!-- FIN DE MUESTREO DE BRIX -->

            <!-- MUESTREO DE PH -->
            <!-- <v-row v-if="cultiveType != 3" class="pt-3 pr-3 pl-3">
              <h3>PH</h3>
              <v-spacer></v-spacer>
              <v-btn rounded color="" fab x-small @click="addPh()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-row> -->
            <!-- <v-divider></v-divider>
            <v-col v-if="cultiveType != 3">
              <v-row> -->
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select
                    :items="itemsDataVariety"
                    item-text="VrtName"
                    item-value="VrtID"
                    v-model="objVarietyPh"
                    return-object
                    label="Variedad"
                    ref="colorSelect"
                    @input="inputVarietyPh($event)"
                  ></s-select>
                </v-col> -->
                <!-- <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  <s-text
                    v-model="FpfPhValue"
                    ref="focusFpfPhValue"
                    label="Valor Ph"
                    decimal
                    @keyupEnter="addPh()"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-0 pt-0">
                  <v-data-table
                    dense
                    :headers="headersPh"
                    :items="itemsDataPh"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeletePh(item)"
                        x-small
                        block
                        rounded
                        color="error"
                      >
                        <v-icon style="font-size: 16px !important"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col> -->
            <!-- FIN DE MUESTREO DE PH  -->

            <!-- MUESTREO DE COLOR -->
            <v-row class="pt-3 pr-3 pl-3">
              <h3>Color</h3>
              <v-spacer></v-spacer>
              <v-btn rounded fab x-small @click="addColor()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pb-0 pl-0">
                  <s-select
                    :items="itemsDataVariety"
                    v-model="objVarietyColor"
                    item-text="VrtName"
                    item-value="VrtID"
                    return-object
                    label="Variedad"
                    ref="colorSelect"
                    @input="inputCaliberTwo($event)"
                  ></s-select> 
                </v-col> -->

                <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  <s-select
                   
                    :items="itemColorVariety"
                    item-text="CbcName"
                    item-value="CcbColor"
                    return-object
                    v-model="ColorTextDescription"
                    label="Color"
                    ref="colorSelect"
                    @input="inputColor($event)"
                     clearable
                  ></s-select>
                  <!-- <s-text label="color"></s-text> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pl-0">
                  <v-data-table
                    dense
                    :headers="headersColor"
                    hide-default-footer
                    :items="itemsDataColor"
                    :items-per-page="-1"
                    disable-sort
                  >
                    <template v-slot:item.CbcName="{ item }">
                      {{ item.CbcName }}
                      <v-chip
                        x-small
                        :color="'#' + item.CbcColor"
                      ></v-chip>
                    </template>

                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteColor(item)"
                        x-small
                        color="error"
                        dark
                        block
                        rounded
                      >
                        <v-icon style="font-size: 16px !important">
                          mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
            <!-- FIN DE MUESTREO DE COLOR -->
          </v-col>

          <v-divider vertical></v-divider>
          <v-col cols="6" lg="4" md="4" class="pt-0">
            <!-- MUESTREO DE FIRMEZA  -->
            <v-row class="pt-3 pr-3 pl-3">
              <h3>Firmeza</h3>
              <v-spacer></v-spacer>
              <v-btn rounded color="" fab x-small @click="addFirmness()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-col>
              <v-row>
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select
                    :items="itemsDataVariety"
                    item-text="VrtName"
                    item-value="VrtID"
                    v-model="objVarietyFirmness"
                    return-object
                    label="Variedad"
                    ref="colorSelect"
                    @input="inputVarietyFirmness($event)"
                  ></s-select>
                </v-col> -->
                <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  <s-text
                    v-model="FffFirmnessValue"
                    ref="focusFffFirmnessValue"
                    label="Valor Firmeza"
                    decimal
                    @keyupEnter="addFirmness()"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-0 pt-0">
                  <v-data-table
                    dense
                    :headers="headersFirmness"
                    :items="itemsDataFirmness"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteFirmness(item)"
                        x-small
                        block
                        rounded
                        color="error"
                      >
                        <v-icon style="font-size: 16px !important"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <!-- FIN DE MUESTREO DE FIRMEZA -->

            <!-- MUESTREO DE SABOR  -->
            <!-- <v-row v-if="cultiveType != 3" class="pt-3 pr-3 pl-3">
              <h3>Sabor</h3>
              <v-spacer></v-spacer>
              <v-btn rounded fab x-small @click="addTaste()"
                ><i class="fas fa-plus"></i
              ></v-btn>
            </v-row>
            <v-divider></v-divider>
            <v-col v-if="cultiveType != 3">
              <v-row> -->
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select
                    :items="itemsDataVariety"
                    v-model="objVarietyTaste"
                    item-text="VrtName"
                    item-value="VrtID"
                    return-object
                    label="Variedad"
                    ref="colorSelect"
                    @input="inputVarietyTaste($event)"
                  ></s-select>
                </v-col> -->
                <!-- <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  <s-text
                    v-model="FtfTasteValue"
                    ref="focusFtfTasteValue"
                    label="Valor Sabor"
                    decimal
                    @keyupEnter="addTaste()"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pl-0 pt-0">
                  <v-data-table
                    dense
                    :headers="headersTaste"
                    :items="itemsDataTaste"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteTaste(item)"
                        x-small
                        block
                        rounded
                        color="error"
                      >
                        <v-icon style="font-size: 16px !important"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col> -->
            <!-- FIN DE MUESTREO DE SABOR -->

            <!-- AGREGAR PESO CUANTO EL PALLET YA SALIO DEL TUNEL DE MADURACIÓN -->

            <v-row v-if="isTunnelMaduration" class="pt-3 pr-3 pl-3">
              <h3>Registrar peso del pallet</h3>
              <v-spacer></v-spacer>
            </v-row>
            <v-col 
              v-if="isTunnelMaduration"
              style="padding-top: 20px"
              cols="12" lg="12" md="12"
              class="pl-0 pb-0"
            >
              <s-text v-model="MtpWeight" label="Peso pallet"></s-text>
            </v-col>
          </v-col>
          <v-divider vertical></v-divider>

          <v-col cols="12" lg="4" md="4" class="pt-0">
            <h3>Defectos</h3>
            <v-col style="padding-top: 30px">
              <v-row style="padding-bottom: 16px">
                <v-col  cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0" v-show="false">
                  <s-text
                    v-model="itemDamage.FdfPercentage"
                    decimal
                    label="Porcentaje (%)"
                  ></s-text>
                </v-col>
                <v-col  cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-text
                    ref="focusFdfAmountFruit"
                    v-model="itemDamage.FdfAmountFruit"
                    number
                    label="Cantidad frutas"
                    readonly
                  ></s-text>
                </v-col>

              
                <!-- <v-col cols="12" lg="12" md="12" sm="12" class="pl-0 pb-0">
                  {{damageCultiveSelected}}
                  <v-checkbox
                    v-model="damageCultiveSelected"
                    v-for="item in DamageCultive"
                    :key="item.DfcID"
                    :label="item.DfcName"
                    :value="item.DfcID"
                  ></v-checkbox>
                </v-col> -->
                <v-col cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select
                    label="Tipo de defecto"
                    :items="DamageCultive"
                    v-model="itemDamage.ObjTypeDefects"
                    item-text="DfcName"
                    item-value="DfcID"
                    return-object
                    @input="inputDamage($event)"
                    >

                  </s-select>
                </v-col>
                <!-- <v-col  cols="12" lg="6" md="6" sm="6" class="pl-0 pb-0">
                  <s-select-definition
                    v-model="itemDamage.ObjTypeDefects"
                    :def="1327"
                    return-object
                    label="Tipo de defecto"
                    @input="inputDamage($event)"
                  >
                  </s-select-definition>
                </v-col> -->
              </v-row>
              <v-row>
                <h3>Muestras</h3>
                <v-spacer></v-spacer>
                <v-btn rounded fab x-small @click="addDamage()"
                  ><i class="fas fa-plus"></i
                ></v-btn>
              </v-row>
              <v-divider></v-divider>
              <v-container style="padding-top: 20px">
                <v-row>
                  <v-col class="pl-0 pt-0">
                    <v-data-table
                      style="padding-bottom: 16px"
                      dense
                      :headers="headerDamage"
                      :items="itemsDataDamage"
                      hide-default-footer
                      :items-per-page="-1"
                      disable-sort
                    >
                      <template v-slot:item.Actions="{ item }">
                        <v-btn
                          @click="clickDeleteDamage(item)"
                          x-small
                          block
                          rounded
                          color="error"
                        >
                          <v-icon style="font-size: 16px !important"
                            >mdi-close</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
              <!-- FIN DE MUESTREO DE DAÑOS -->

              <!-- botones -->
              <!-- <v-row style="padding-top: 40px">
                <v-spacer></v-spacer>
                <v-col cols="12" lg="4" md="6" sm="6">
                  <v-btn block rounded color="warning">Cancelar</v-btn>
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="6">
                  <v-btn
                    class="ml-3"
                    block
                    rounded
                    color="primary"
                    @click="save()"
                    >Guardar</v-btn
                  >
                </v-col>
              </v-row> -->
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </div>
</template>
<script>


import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService";
import _sCultiveByColorService from "@/services/FrozenProduction/CultiveByColorService";
import _sReceptionStorageService from "@/services/Technicalassistance/ReceptionStorageService";
import _sCustomerMaduration from "@/services/FrozenProduction/FrzCustomersMaturationService";//jmr
import _sBrixFreshService from "@/services/FreshProduction/BrixFreshService";
import _sDamage from "@/services/FreshProduction/SampleDamage/SampleQuality";
import SampleDamage from "@/views/FreshProduction/RawMaterialReception/SampleDamage/SampleDamage";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import _sParameter from "@/services/General/ParameterService.js";
import SselectClientSap from "../../../components/Sale/SselectClientSap.vue";
import _sHelper from "@/services/HelperService";

import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling.js";
import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService";
import { Datetime } from 'vue-datetime';


export default {
  components: { SampleDamage, SSelectVariety, SselectClientSap, Datetime },
  props: {
    items: null,
    isSamplingEdit: null,
    LlpID: 0,
    isEdit:false
  },

  data: () => ({
    damageCultiveSelected: [],
    objVarietyBRIX: null,
    objVarietyColor: null,
    objVarietyDamage: null,
    pointDefect : 0,
    objVarietyPh : null,
    objVarietyFirmness : null,
    objVarietyTaste : null,
    DamageCultive : null,
    cultiveType : 0,
    //FfsHour:"",//jmr
    itemHead: {
      //FfsHour: "",
      CumId: 0,
      TypeTurn: 0,
    },

    photoSampling: [],
    MtpWeight: 0,
    itemsDataVariety: [],
    listCustomer:[], //jmr
    itemDamage: {},
    itemDamageData: [],
    FfsID: 0,

    isTunnelMaduration: false,
    editMtpWeight: false,

    dialogCapataz: false,
    objForeman: null,
    objForemanSend: null,
    maxBRIX: 0,
    minBRIX: 0,
    minFirmnes: 0,
    maxFirmnes: 0,
    minColor: 0,
    maxColor: 0,
    minPh: 0,
    maxPh: 0,
    minTaste: 0,
    maxTaste: 0,
    quantityBRIX: 0,
    percentageSample: 0,
    loadDamage: false,
    itemsGang: [],
    itemSampleDamage: [],
     
    
    NumberJabasM : 5,
    itemsDataColor: [],
    itemsDataDamage: [],
    itemsDataBrix: [],
    itemsDataFirmness: [],
    itemsDataPh: [],
    itemsDataTaste: [],

    itemSample: {},
    itemsDeleteBrix: [],
    itemsDeleteColor: [],
    itemsDeleteDamage: [],
    itemsDeleteFirmness: [],
    itemsDeleteTaste: [],
    itemsDeletePh: [],

    minFirmnes2: null,
    TypeVariety1: 0,
    DedDescription1: "",
    TypeVariety2: 0,
    DedDescription2: "",
    TypeVariety3: 0,

    TypeVarietyFirmness: 0,
    TypeVarietyTaste: 0,
    TypeVarietyPh: 0,

    DedDescriptionFirmness: "",
    DedDescriptionPh: "",
    DedDescriptionTaste: "",

    DedDescription3: "",
    FfsBrixValue: "",
    FpfPhValue: "",
    FtfTasteValue: "",
    FffFirmnessValue: "",

    TypeDefectsName : "",
    TypeDefects: 0,

    Actions: "",
    itemColorVariety: null,
    HexadecimalColor: "",
    ColorDescription: "",
    ClienteMat: "",
    ColorID: 0,
    ColorTextDescription: "",
    itemsData: {},
    BrxID: 0,
    headersBrix: [
      
      { text: "Variedad", value: "VrtDescription" },
      { text: "Brix", value: "FfsBrixValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],
    headersColor: [
      { text:"item", value :"Line"},
      { text: "Variedad", value: "VrtDescription" },
      { text: "Color", value: "CbcName", width: 150 },
      { text: "", value: "Actions", width: 80 },
    ],
    headerDamage: [
      { text:"item", value :"Line"},
      { text: "Variedad", value: "VrtDescription", sortable: false },
      {
        text: "Cantidad",
        value: "FdfAmountFruit",
        width: 100,
        sortable: false,
      },
      {
        text: "Tipo de defecto",
        value: "DfcName",
        width: 130,
        sortable: false,
      },
      { text: "", value: "Actions", width: 80, sortable: false },
    ],
    headersFirmness: [
      { text:"item", value :"Line"},
      { text: "Variedad", value: "VrtDescription" },
      { text: "Firmeza", value: "FffFirmnessValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],
    headersTaste: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Sabor", value: "FtfTasteValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],

    headersPh: [
      { text: "Variedad", value: "VrtDescription" },
      { text: "Ph", value: "FpfPhValue", width: 70 },
      { text: "", value: "Actions", width: 80 },
    ],
    searchLlpID: ''
  }),

  methods: {
    // VALIDACIÓN GENERAL
    // isValidated() {
    //   var validate = false;
    //   let countBrix = this.itemsDataBrix.length;
    //   let countColor = this.itemsDataColor.length;

    //   if (this.objForeman == null) {
    //     this.$fun.alert("No existe Capataz Asociado", "warning");
    //     return false;
    //   }
    //   if (
    //     this.itemSample.BrxNumberJabas <= 0 ||
    //     this.itemSample.BrxNumberJabas == null
    //   ) {
    //     this.$refs.FocuesNumberJabas.error(
    //       "Debe insertar la cantidad de cajas"
    //     );
    //   } else if (countBrix <= 0) {
    //     this.$fun.alert("Debe registrar Datos de brix", "warning");
    //   } else if (countColor <= 0) {
    //     this.$fun.alert("Debe registrar Datos de Color", "warning");
    //   } else {
    //     validate = true;
    //   }
    //   return validate;
    // },

    // VALIDACION GENERAL 
    searchSamplingFrosen()
    {
      if(this.searchLlpID.length >= 4)
      {
        _sFrozenSampling.samplingSearchLlpID({LlpID : this.searchLlpID}, this.$fun.getUserID()).then((r) => {
          if(r.status == 200)
          {
            if (r.data.DataBrixFrozen != null) {
          
              // // ID DE LA CABECERA
              // this.FfsID = r.data.FfsID;
              this.itemHead = r.data;

              r.data.DataBrixFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataBrix = r.data.DataBrixFrozen;

              r.data.DataColorFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataColor = r.data.DataColorFrozen;

              r.data.DataDamageFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataDamage = r.data.DataDamageFrozen;

              r.data.DataFirmnessFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataFirmness = r.data.DataFirmnessFrozen;

              r.data.DataTasteFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataTaste = r.data.DataTasteFrozen;

              r.data.DataPhFrozen.forEach((el, index) => {
                el.Line = index + 1;
              });
              this.itemsDataPh = r.data.DataPhFrozen;

              // this.isEdit = true;
            } else {
              this.FfsID = 0;
              this.itemHead = [];
              this.itemsDataBrix = [];
              this.itemsDataColor = [];
              this.isEdit = false;
            }
          }
        });
      }

  
    },
    validate() {

      let isValidate = false;
      let countBrix = this.itemsDataBrix.length;
      let countDamage = this.itemsDataDamage.length;
      let countColor = this.itemsDataColor.length;
      let countPh = this.itemsDataPh.length;
      let countFirmness = this.itemsDataFirmness.length;

    },

    inputColor(item) {
      this.HexadecimalColor = item ? item.CbcColor : "";
      this.ColorDescription = item ? item.CbcName : "";
      this.ColorID = item ? item.CbcID : "";
    },

    inputCaliber(item) {
      
      this.TypeVariety1 = item ? item.VrtID : "";
      this.DedDescription1 = item ? item.VrtName : "";
      // this.getColorVariety(this.TypeVariety1);
    },

    inputCaliberTwo(item) {
      this.TypeVariety2 = item ? item.VrtID : "";
      this.DedDescription2 = item ? item.VrtName : "";

      // this.getColorVariety(this.TypeVariety2);
    },

    inputVariety3(item) {
      this.TypeVariety3 = item ? item.VrtID : "";
      this.DedDescription3 = item ? item.VrtName : "";
    },

    inputVarietyFirmness(item) {
      this.TypeVarietyFirmness = item ? item.VrtID : "";
      this.DedDescriptionFirmness = item ? item.VrtName : "";
    },

    inputVarietyTaste(item) {
      this.TypeVarietyTaste = item ? item.VrtID : "";
      this.DedDescriptionTaste = item ? item.VrtName : "";
    },

    inputVarietyPh(item) {
      this.TypeVarietyPh = item ? item.VrtID : "";
      this.DedDescriptionPh = item ? item.VrtName : "";
    },

    clickDeleteFirmness(item) {
      
      this.itemsDataFirmness = this.itemsDataFirmness.filter(
        (x) => {
          return x.Line != item.Line
          /* if(x.FfsID == 0)
          {
            return x.Line != item.Line
          }
          else{
            return x.FffID != item.FffID
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FffID != undefined)
      {
        if (this.isEdit) this.itemsDeleteFirmness.push(item);
      }
      
    },

    clickDeleteTaste(item) {
      
      this.itemsDataTaste = this.itemsDataTaste.filter(
        (x) => {
          return x.Line != item.Line
         /*  if(x.FfsID == 0)
          {
            return x.Line != item.Line
          }
          else{
            return x.FtfID != item.FtfID
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FtfID != undefined)
      {
        if (this.isEdit) this.itemsDeleteTaste.push(item);
      }
      
    },

    clickDeletePh(item) {
      this.itemsDataPh = this.itemsDataPh.filter(
        (x) => {
          return x.Line != item.Line
          /* if(x.FfsID == 0){
            return x.Line != item.Line
          }else{
            return x.FpfID != item.FpfID
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FpfID != undefined)
      {
        if (this.isEdit) this.itemsDeletePh.push(item);
      }
      
    },

    clickDeleteBrix(item) {
      this.itemsDataBrix = this.itemsDataBrix.filter(
        (x) => {
          return x.Line != item.Line
          /* if(x.FfsID == 0){
            return x.Line != item.Line
          }
          else{
            return x.FbfID != item.FbfID
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FbfID != undefined){
        if (this.isEdit) this.itemsDeleteBrix.push(item);
      }
      
    },

    clickDeleteColor(item) {
      
      this.itemsDataColor = this.itemsDataColor.filter(
        (x) => {
          return x.Line != item.Line
          /* if(x.FfsID == 0)
          {
            return x.Line != item.Line
          }
          else{
            return x.FcfID != item.FcfID
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FcfID != undefined)
      {
        if (this.isEdit) this.itemsDeleteColor.push(item);
      }
      
    },

    clickDeleteDamage(item) {
      
      this.itemsDataDamage = this.itemsDataDamage.filter(
        (x) => {
          return x.Line != item.Line;
          /* if(x.FfsID == 0){
            return x.Line != item.Line;
          }
          else{
            return x.FdfID != item.FdfID;
          } */
        }
      );
      item.SecStatus = 0;
      if(item.FdfID != undefined){
        if (this.isEdit) this.itemsDeleteDamage.push(item);
      }
      
    },

    addFirmness() {

      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }
      if(this.itemHead.FfSsampledUnits == this.itemsDataFirmness.length)
      {
        this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
        return;
      }
      
      // if(this.itemHead.FfSsampledUnits == this.itemsDataPh.length) //jmr
      // {
      //   this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
      //   return;
      // }


      if (this.FffFirmnessValue.trim() == "") {
        this.$refs.focusFffFirmnessValue.error(
          "Debe ingresar un número de firmeza valido"
        );
        return;
      }

      if (this.FffFirmnessValue <= 0) {
        this.$refs.focusFffFirmnessValue.error(
          "Debe ingresar un número de firmeza valido"
        );
      } else {
        if (
          this.FffFirmnessValue >= this.minFirmnes2 &&
          this.FffFirmnessValue <= this.maxFirmnes
        ) {
          let itemsFirmness = {};
          itemsFirmness.FfsID = this.FfsID ? this.FfsID : 0;
          itemsFirmness.VrtID = this.TypeVariety1;
          itemsFirmness.FffFirmnessValue = this.FffFirmnessValue;
          itemsFirmness.VrtDescription = this.DedDescription1;
          itemsFirmness.Line = this.itemsDataFirmness.length + 1;
          this.itemsDataFirmness.push(itemsFirmness);

          this.FffFirmnessValue = "";
          this.$refs.focusFffFirmnessValue.focus();
        } else {
          this.$fun.alert(
            "El valor de la Firmeza fuera del rango " +
              this.minFirmnes2 +
              " - " +
              this.maxFirmnes,
            "warning"
          );
        }
      }
    },

    addTaste() {
      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }
      if(this.itemHead.FfSsampledUnits == this.itemsDataTaste.length)
      {
        this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
        return;
      }
      if (this.FtfTasteValue.trim() == "") {
        this.$refs.focusFtfTasteValue.error("Debe ingresar un Sabor valido");
        return;
      }

      if (this.FtfTasteValue <= 0) {
        this.$refs.focusFtfTasteValue.error("Debe ingresar un Sabor valido");
      } else {
        if (
          this.FtfTasteValue >= this.minTaste &&
          this.FtfTasteValue <= this.maxTaste
        ) {
          let itemsTaste = {};
          itemsTaste.FfsID = this.FfsID ? this.FfsID : 0;
          itemsTaste.VrtID = this.TypeVariety1;
          itemsTaste.FtfTasteValue = this.FtfTasteValue;
          itemsTaste.VrtDescription = this.DedDescription1;
          itemsTaste.Line = this.itemsDataTaste.length + 1;
          this.itemsDataTaste.push(itemsTaste);

          this.FtfTasteValue = "";
          this.$refs.focusFtfTasteValue.focus();
        } else {
          this.$fun.alert(
            "El valor de Sabor fuera del rango " +
              this.minTaste +
              " - " +
              this.maxTaste,
            "warning"
          );
        }
      }
    },

    addPh() {
      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }
      if(this.itemHead.FfSsampledUnits == this.itemsDataPh.length)
      {
        this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
        return;
      }
      if (this.FpfPhValue.trim() == "") {
        this.$refs.focusFpfPhValue.error("Debe ingresar un Ph valido");
        return;
      }

      if (this.FpfPhValue <= 0) {
        this.$refs.focusFpfPhValue.error("Debe ingresar un Ph valido");
      } else {
        if (this.FpfPhValue >= this.minPh && this.FpfPhValue <= this.maxPh) {
          let itemsPh = {};
          itemsPh.FfsID = this.FfsID ? this.FfsID : 0;
          itemsPh.VrtID = this.TypeVariety1;
          itemsPh.FpfPhValue = this.FpfPhValue;
          itemsPh.VrtDescription = this.DedDescription1;
          itemsPh.Line = this.itemsDataPh.length + 1;
          this.itemsDataPh.push(itemsPh);

          this.FpfPhValue = "";
          this.$refs.focusFpfPhValue.focus();
        } else {
          this.$fun.alert(
            "El valor de PH fuera del rango " + this.minPh + " - " + this.maxPh,
            "warning"
          );
        }
      }
    },

    addBrix() {
      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }
      if(this.itemHead.FfSsampledUnits == this.itemsDataBrix.length)
      {
        this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
        return;
      }
      if (this.FfsBrixValue.trim() == "") {
        this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
        return;
      }

      if (this.FfsBrixValue <= 0) {
        this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
      } else {
        if (
          this.FfsBrixValue >= this.minBRIX &&
          this.FfsBrixValue <= this.maxBRIX
        ) {
          let itemsBrix = {};
          itemsBrix.FfsID = this.FfsID ? this.FfsID : 0;
          itemsBrix.VrtID = this.TypeVariety1;
          itemsBrix.FfsBrixValue = this.FfsBrixValue;
          itemsBrix.VrtDescription = this.DedDescription1;
          itemsBrix.Line = this.itemsDataBrix.length + 1;
          this.itemsDataBrix.push(itemsBrix);

          

          this.FfsBrixValue = "";
          this.$refs.focusBrixValue.focus();
        } else {
          this.$fun.alert(
            "El valor de BRIX fuera del rango " +
              this.minBRIX +
              " - " +
              this.maxBRIX,
            "warning"
          );
        }
      }
    },

    inputDamage(item){
      
      this.TypeDefects = item ? item.DfcID : "";
      this.TypeDefectsName = item ? item.DfcName : "";  
    },

    addDamage() {

      if(this.itemHead.FfSsampledUnits == this.itemsDataDamage.length) //jmr
      {
        this.$fun.alert("Todas las muestras de defectos han sido ingresadas", "warning");
        return;
      }
      
      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }

      if(!this.itemDamage.FdfAmountFruit){ //--jmr
        this.$fun.alert("Debe ingresar la cantidad de frutas en defectos", "warning");
        this.$refs.focusFdfAmountFruit.focus();
        return;
      }

      if(this.itemDamage.ObjTypeDefects == undefined){
        this.$fun.alert("Ingrese tipo de defecto", "warning");
        return;
      }

      


      // verificar
      // if(this.itemHead.FfsNumberJabas == this.itemsDataDamage.length)
      // {
      //   this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
      //   return;
      // }

      let itemsDamage = {};
      itemsDamage.FfsID = this.FfsID ? this.FfsID : 0;
      itemsDamage.VrtID = this.TypeVariety1;
      itemsDamage.VrtDescription = this.DedDescription1;

      itemsDamage.DfcID = this.TypeDefects;
      itemsDamage.DfcName = this.TypeDefectsName;

      itemsDamage.Line = this.itemsDataDamage.length + 1;
      itemsDamage.FdfPercentage = this.itemDamage.FdfPercentage;
      itemsDamage.FdfAmountFruit = this.itemDamage.FdfAmountFruit;
      itemsDamage.UsrCreateID = this.$fun.getUserID();
      itemsDamage.UsrUpdateID = this.$fun.getUserID();

      this.itemsDataDamage.push(itemsDamage);
      
      this.itemDamage = {};

      this.itemDamage.FdfAmountFruit = 1;
    },

    addColor() {
      
      if (this.ColorDescription == "") {
        this.$fun.alert("Debe seleccionar un color", "warning");
        return;
      }
      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesFfSsampledUnits.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }

      if(this.itemHead.FfSsampledUnits == this.itemsDataColor.length)
      {
        this.$fun.alert("Todas las muestras han sido ingresadas", "warning");
        return;
      }

      let itemsColor = {};
      itemsColor.FfsID = this.FfsID ? this.FfsID : 0;
      itemsColor.VrtID = this.TypeVariety1;
      itemsColor.VrtDescription = this.DedDescription1;
      itemsColor.Line = this.itemsDataColor.length + 1;
      itemsColor.CbcName = this.ColorDescription;
      itemsColor.CbcID = this.ColorID;
      itemsColor.CbcColor = this.HexadecimalColor;

      this.itemsDataColor.push(itemsColor);
      this.ColorTextDescription =
        this.itemColorVariety.length > 0 ? this.itemColorVariety[0] : null;

    },

    uploadFilePhoto() {
      if (this.photoSampling != null) {
        var formData = new FormData();
        formData.append("file", this.photoSampling);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 10)
          .then((resp) => {
            if (resp.status == 200) {
              this.photoSampling = [];
              return;
            }
          });
      } else {
        this.$fun.alert("Sin archivo para subir");
      }
    },

    save() {
      /* VALIDACIONES */
      

      if (!this.itemHead.CumId) {// verif
        this.$fun.alert("Debe seleccionar un cliente", "warning");
       
        return;
      }


      if(this.itemHead.FfSsampledUnits == ""){
        this.$refs.FocuesNumberJabas.error(
          "Debe ingresar la cantidad de unidades muestreadas"
        );
        return;
      }

      if(this.itemsDataBrix.length < this.itemHead.FfSsampledUnits ){ //--jmr
        if(this.cultiveType != 3){
          this.$fun.alert("Debe ingresar todos los valores del BRIX", "warning");
          this.$refs.focusBrixValue.focus(); // 
          return;
        }
      }

      if(this.itemsDataFirmness.length < this.itemHead.FfSsampledUnits){ //--jmr
        this.$fun.alert("Debe ingresar todas las muestras de firmeza", "warning");
        this.$refs.focusFffFirmnessValue.focus();
        return;
      }

      // if(this.itemsDataFirmness.length < this.itemHead.FfSsampledUnits){ //--jmr
      //   this.$fun.alert("Debe ingresar todas las muestras de firmeza", "warning");
      //   //this.$refs.focusFffFirmnessValue.focus(); 
      //   return;
      // }

      if(this.itemsDataDamage.length < this.itemHead.FfSsampledUnits){ //--jmr
        this.$fun.alert("Debe ingresar todos los defectos", "warning");
        this.$refs.focusFdfAmountFruit.focus();
        return;
      }

      // if(this.itemsDataPh.length < this.itemHead.FfSsampledUnits){ //--jmr
      //   if(this.cultiveType != 3){
      //   this.$fun.alert("Debe ingresar todos los valor de PH", "warning");
      //   return;
      //   }
      // }

      // if(this.itemsDataTaste.length < this.itemHead.FfSsampledUnits){ //--jmr
      //   if(this.cultiveType != 3){
      //   this.$fun.alert("Debe ingresar todos los valor de sabor", "warning");
      //   return;
      //   }
      // }

      if(this.itemsDataColor.length < this.itemHead.FfSsampledUnits){ //--jmr
        this.$fun.alert("Debe ingresar todos los valor de color", "warning");
        return;
      }
     


      /* if(this.itemsDataBrix.length == 0){
        this.$fun.alert("Debe ingresar muestre de Brix");
        this.$refs.focusBrixValue.focus();
        return;
      }
      if(this.itemsDataPh.length == 0){
        this.$fun.alert("Debe ingresar muestre de PH");
        this.$refs.focusFpfPhValue.focus();
        return;
      }
      if(this.itemsDataColor.length == 0){
        this.$fun.alert("Debe ingresar muestre de Color");
        this.$refs.colorSelect.focus();
        return;
      }
      if(this.itemsDataFirmness.length == 0){
        this.$fun.alert("Debe ingresar muestre de Firmeza");
        this.$refs.focusFffFirmnessValue.focus();
        return;
      }
      if(this.itemsDataTaste.length == 0){
        this.$fun.alert("Debe ingresar muestre de Sabor");
        this.$refs.focusFtfTasteValue.focus();
        return;
      }
      if(this.itemsDataDamage.length == 0){
        this.$fun.alert("Debe ingresar muestre de Calidad");
        this.$refs.focusFdfAmountFruit.focus();
        return;
      } */
      /* FIN DE VALIDACIONES */

      // ACTUALIZAMOS EL PESO EN LA TABLA DE "FrzMaturationTunnelPallet"
      let objWeight = {
        MtpID: this.items.MtpID,
        MtpWeight: this.MtpWeight,
        EditMtpWeight: 2,
      };
      /* if(this.items.MtpID != null){
        _sMaturationTunnelPalletService
        .editWeight(objWeight, this.$fun.getUserID())
        .then((r) => {
          
        });
      } */
      
      

      // debemos coger el Id de la fila seleccionada para ver donde lo guardamos
      this.itemHead.RcfID = this.items.RcfID ? this.items.RcfID : 0;
      this.itemHead.LlpID = this.LlpID;
      this.itemHead.PseID = this.items.PseID ? this.items.PseID : 0;
      this.itemHead.MtpID = this.items.MtpID ? this.items.MtpID : 0;

      this.itemHead.CardName = "";

      //this.itemHead.CumID = this.CumObject.//estaba comentado
      this.itemHead.FfsState = 1;
      this.itemHead.SecStatus = 1;
      this.itemHead.UsrUpdateID = this.$fun.getUserID();
      this.itemHead.UsrCreateID = this.$fun.getUserID();
     

      this.itemHead.FfsID = this.FfsID ? this.FfsID : 0;

      this.itemHead.FfsImage =
        this.photoSampling != null ? this.photoSampling.name : "";

      if(this.isEdit){
        
        //this.itemsDataBrix = this.itemHead.DataBrixFrozen;
      }
      
      //let itemsDataDamageAux = this.itemsDataDamage;



      this.itemHead.DataDamageFrozen = [...this.itemsDataDamage, ...this.itemsDeleteDamage];
      this.itemHead.DataBrixFrozen = [...this.itemsDataBrix, ...this.itemsDeleteBrix];
      this.itemHead.DataColorFrozen = [...this.itemsDataColor, ...this.itemsDeleteColor];
      this.itemHead.DataFirmnessFrozen = [...this.itemsDataFirmness, ...this.itemsDeleteFirmness];
      this.itemHead.DataTasteFrozen = [...this.itemsDataTaste, ...this.itemsDeleteTaste];
      this.itemHead.DataPhFrozen = [...this.itemsDataPh, ...this.itemsDeletePh];


      
      // return
      

     /*  let itemsDataBrixAux = this.itemsDataBrix;
      let itemsDataColorAux = this.itemsDataColor;
      this.itemHead.DataBrixFrozen = itemsDataBrixAux;
      this.itemHead.DataColorFrozen = itemsDataColorAux;

      let itemsDataFirmnessAux = this.itemsDataFirmness;
      let itemsDataTaste = this.itemsDataTaste;
      let itemsDataPh = this.itemsDataPh;
      this.itemHead.DataFirmnessFrozen = itemsDataFirmnessAux;
      this.itemHead.DataTasteFrozen = itemsDataTaste;
      this.itemHead.DataPhFrozen = itemsDataPh; */

      /* this.itemHead.DataBrixFrozen.push(...this.itemsDeleteBrix);
      this.itemHead.DataColorFrozen.push(...this.itemsDeleteColor);
      this.itemHead.DataDamageFrozen.push(...this.itemsDeleteDamage);

      this.itemHead.DataFirmnessFrozen.push(...this.itemsDeleteFirmness);
      this.itemHead.DataTasteFrozen.push(...this.itemsDeleteTaste);
      this.itemHead.DataPhFrozen.push(...this.itemsDeletePh); */

      this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
        if (r.value) {
          if(this.isEdit){

            /* this.itemHead.DataBrixFrozen.push(...this.itemsDeleteBrix);
            this.itemHead.DataColorFrozen.push(...this.itemsDeleteColor); */
            //this.itemHead.DataDamageFrozen.push(...this.itemsDeleteDamage);

           /*  this.itemHead.DataFirmnessFrozen.push(...this.itemsDeleteFirmness);
            this.itemHead.DataTasteFrozen.push(...this.itemsDeleteTaste);
            this.itemHead.DataPhFrozen.push(...this.itemsDeletePh); */
          }
          //this.itemHead.FfsPlace = 2;
          
          _sFrozenSampling
            .save(this.itemHead, this.$fun.getUserID())
            .then((r) => {
              if (r.status == 200) {
                this.$fun.alert("Registrado correctamente", "success");
                this.$emit("close");
              }
            });
           }
      });
    },

    getSample() {

      
      //  BUSCAMOS SI HAY REGISTRO DE ESE DATO EN NUESTRA TABLA DE MUESTREO
      let obj = {
        TypeShip: 3,
        MtpStatus : this.items.MtpStatus,
        RcfID: 0,
        PseID: 0,
        FfsID: this.items.FfsID,
        MtpID: this.items.MtpID,
      };

      // if (this.items.FfsCliente == null){
      //   obj.MtpStatus = 1
      // }

      _sFrozenSampling.sampling(obj, this.$fun.getUserID()).then((r) => {
        
        if (r.data.DataBrixFrozen != null) {
          
          // // ID DE LA CABECERA
          this.FfsID = r.data.FfsID;
          this.itemHead = r.data;

          r.data.DataBrixFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataBrix = r.data.DataBrixFrozen;

          r.data.DataColorFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataColor = r.data.DataColorFrozen;

          r.data.DataDamageFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataDamage = r.data.DataDamageFrozen;

          r.data.DataFirmnessFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataFirmness = r.data.DataFirmnessFrozen;

          r.data.DataTasteFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataTaste = r.data.DataTasteFrozen;

          r.data.DataPhFrozen.forEach((el, index) => {
            el.Line = index + 1;
          });
          this.itemsDataPh = r.data.DataPhFrozen;

          this.isEdit = true;
        } else {
          this.FfsID = 0;
          this.itemHead = [];
          this.itemsDataBrix = [];
          this.itemsDataColor = [];
          this.isEdit = false;
        }
      });
    },

    getColorVariety(items) {
     
      _sCultiveByColorService
        .list(items, this.$fun.getUserID())
        .then((r) => {
          this.itemColorVariety = r.data;
          
          // debugger;
          if (this.itemColorVariety.length > 0)
            this.ColorTextDescription = this.itemColorVariety[0];
        });
    },
  },

  created() {

    //  parametro para punto de muestreo por defecto

    let b = {
      PrmName: "CongeladoFirmezaMinima"
    };

    _sParameter.search(b, this.$fun.getUserID()).then((r) => {
      
      this.minFirmnes2 = r.data.PrmValue
      
    });

    

    // TRAEMOS LOS COLORES POR CULTIVO 
    this.getColorVariety(this.items);

    // TRAEMOS LOS DAÑOS POR CULTIVO 
    if(this.items.TypeCultive != 0) {
      _sDamageFlawCultiveService.list(this.items, this.$fun.getUserID())
      .then((r)=>{
        if(r.status == 200){
          
          this.DamageCultive = r.data;
          this.cultiveType = this.DamageCultive[0].TypeCultive;
        }
      });
    }


    if (this.isTunnelMaduration) {

      let objWeight = {
        MtpID: this.items.MtpID,
        MtpWeight: this.MtpWeight,
        EditMtpWeight: 1,
      };
      _sMaturationTunnelPalletService
        .editWeight(objWeight, this.$fun.getUserID())
        .then((r) => {
          this.MtpWeight=r.data[0].MtpWeight;
        });
    }

    // jmr
    
      _sCustomerMaduration
        .list(
          
          this.$fun.getUserID()
        )
        .then((r) => {
          this.listCustomer = r.data;
          if (this.listCustomer.length > 0) {
            //this.objCustMaturation = r.data[0]
            this.CumId = r.data[0]
            
          }
        });
    
    //

    
    if (this.items.VrtID == undefined) {
     
      _sReceptionStorageService
        .GetDetails(
          { PseID: this.items.PseID, PseState: 1 },
          this.$fun.getUserID()
        )
        .then((r) => {
          this.itemsDataVariety = r.data;
          if (this.itemsDataVariety.length > 0) {
            this.objVarietyBRIX = r.data[0];
            this.objVarietyColor = r.data[0];
            this.objVarietyDamage = r.data[0];
            this.objVarietyPh = r.data[0];
            this.objVarietyFirmness = r.data[0];
            this.objVarietyTaste = r.data[0];
          }
        });

    } else {
      let itemsVariety = {};
      itemsVariety.VrtID = this.items.VrtID;
      itemsVariety.VrtName = this.items.VrtName;

      this.itemsDataVariety.push(itemsVariety);
      this.objVarietyBRIX = itemsVariety;
      this.objVarietyColor = itemsVariety;
      this.objVarietyDamage = itemsVariety;
      this.objVarietyPh = itemsVariety;
      this.objVarietyFirmness = itemsVariety;
      this.objVarietyTaste = itemsVariety;
   
    }

    let a = [];
    a.push(
      "CongeladoBrixMinimo",
      "CongeladoBrixMaximo",
      //  "CantidadMuestraBRIX",
      // "PorcentajeMuestraLote",
      "CongeladoFirmezaMinima",
      "CongeladoFirmezaMaxima",
      "CongeladoSaborMinimo",
      "CongeladoSaborMaximo",
      "CongeladoPhMinimo",
      "CongeladoPhMaximo"
    );
    _sParameter.list(a, this.$fun.getUserID()).then((r) => {
      
      this.minBRIX = r.data[0];
      this.maxBRIX = r.data[1];
      
      this.minFirmnes = r.data[2];
      this.maxFirmnes = r.data[3];
      this.minPh = r.data[4];
      this.maxPh = r.data[5];
      this.minTaste = r.data[6];
      this.maxTaste = r.data[7];
    });


    if (this.isSamplingEdit) {
      
      this.getSample();
      this.isTunnelMaduration = false;

    } else {
      this.isTunnelMaduration = this.items.MtpStatus == 2 ? true : false;
    }
    
  },

  mounted(){
    
    this.itemHead.FfSsampledUnits = 5;
    this.itemHead.FfsPointSampling = 8;
    this.itemDamage.FdfAmountFruit = 1;


    this.itemHead.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
   //this.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
  }
};
</script>
<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>