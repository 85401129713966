<template>
  <div>
    <!-- <v-container> -->
      <s-crud
        title="Listado de Muestreos"
        :config="config"
        
        @rowSelected="rowSelected($event)"
        :filter="filter"
        close
        @close="close()"
        height="auto"
        search-input
        
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center" class="s-col-form">
              <v-col cols="12" lg="4" md="6"
                ><s-date
                  label="Fecha Inicio"
                  v-model="filter.DateBegin"
                ></s-date>
              </v-col>
              <v-col cols="12" lg="4" md="6"
                ><s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:Actions="{ row }">
          <v-btn
            color="info"
            x-small
            fab
            @click="openEditSampling(row)"
          >
            <v-icon medium>fa-thin fa-pen</v-icon>
          </v-btn>
        </template>
      </s-crud>
    <!-- </v-container> -->

    <!-- Modal para editar los muestreos -->
    <v-dialog
      v-model="editSampling"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="editSampling"
      persistent
    >
      <v-card class="elevation-0">
        <sumpling-frozen
          :isEdit="editSampling"
          :items="itemEditsampling"
          @close="editSampling = false"
          :isSamplingEdit="isSamplingEditG"
          edit
        ></sumpling-frozen>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling";
import SumplingFrozen from "@/views/FrozenProduction/ZoomSelection/SumplingFrozen";

export default {
  components: {SumplingFrozen},
  props: {
    dataPallet: null,
  },

  data() {
    return {      
      isSamplingEditG : false,
      editSampling : false,
      itemEditsampling : {},
      filter: { DateBegin: null, DateEnd: null, MtpID: this.dataPallet.MtpID, LlpID: this.dataPallet.LlpID },
      config: {
        service: _sFrozenSampling,
        model: {
          FfsID: "ID",
          FfsDateSampling: "date",
          Actions: "",
          VrtID: "number",
          FfsHour: "time2"
        },
        headers: [
          { text: "Editar", value: "Actions", width: 40 },
          { text: "Cod", value: "FfsID", width: 60 },
          /* { text: "Cod pallet", value: "MtpID", width: 60 }, */
          { text: "Fecha", value: "FfsDateSampling", width: 60 },
          { text: "Hora", value: "FfsHour", width: 60 },
          { text: "N° Jabas", value: "FfsNumberJabas", width: 60 },
          /* { text: "Cliente", value: "FfsCliente", width: 60 }, */
          /* { text: "Peso", value: "MtpWeight", width: 60 }, */
          { text: "Variedad", value: "VrtName", width: 60 },
        ],
      },
    };
  },

  methods: {
    rowSelected(item) {
      console.log("detalle seleccionado", item);

      // console.log('Mostrando si hay cliente', item[0].FfsCliente)

      // if(item[0].FfsCliente == null){
      //   item[0].MtpStatus = 1
      // }


      this.itemEditsampling = item[0];
      console.log('Mostramos para editar', item)
    },

    openEditSampling(item){
      this.isSamplingEditG = true;
      this.editSampling = true;

      // alert('Mostramos si hay cliente', item.Ffscliente)
      
      console.log('Mostramos si hay cliente', item);

      this.itemEditsampling = item;
      this.itemEditsampling.TypeCultive = this.dataPallet.TypeCultive;
    },

    close(){
      this.$emit('close')
    }
  },
  created() {
    
    console.log('Mostrando datos david', this.dataPallet)
    // debugger;

  },
};
</script>
