<template>
	<div>
		<s-toolbar
			label="Registrar saldos"
			dark
			:color="'#BAB6B5'"
			close
			@close="CloseBalance()"
			save
			@save="AddBalance()"
			class="mb-6"
    ></s-toolbar>
		<v-container>
			<v-row align="start" justify="end">
				
				<v-col cols="12" align="center">
					<b><span v-if="pallet.LlpQuantityJaba != null" style="font-size: 18px" >{{ pallet.TypeCropName + " || " + pallet.TypeCultiveName + " || " + pallet.VrtName + " || " + " N° Jabas: " + pallet.LlpQuantityJaba}}</span></b>
				</v-col>
				
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
					<s-text
						ref="textNumberJabas"
						v-model="palletNew.MbpQuantityJaba"
						label="N° Jabas"
					>
					</s-text>
				</v-col>
				<v-col cols="6" lg="4" md="6" class="s-col-form">
					<s-text
						v-model="palletNew.MbpWeightNew"
						decimal
						ref="textMbpWeightNew"
						label="Peso B. Pallet Creado"
						@input="calculateOldWeight($event)"
					>
					</s-text>
				</v-col>
				<v-col cols="6" lg="4" md="6" class="s-col-form">
					<s-text
						v-model="palletNew.LlpWeightOriginalGross"
						decimal
						ref="textMbpWeightOld"
						label="Peso B. Pallet Anterior"
					>
					</s-text>
				</v-col>
				<v-col cols="12" lg="6" md="6" sm="12" class="s-col-form">
					<s-select-fruit-state
						v-model="palletNew.FtsID"
						full
						:TypeCultive="TypeCultive"
						label="Estado Fruta"
					></s-select-fruit-state>
				</v-col>
				<v-col cols="12" lg="6" md="6" sm="12" class="s-col-form">
					<s-select-definition
						add
						:def="1363"
						label="Tipo de parihuela"
						v-model="palletNew.TypePalletMate"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>

		</v-container>


	</div>
</template>

<script>
	import SSelectFruitState from '../../../components/FrozenProduction/FruitState/SSelectFruitState.vue';

	import _sMaturationTunnelPalletReEntryService from "@/services/FrozenProduction/MaturationTunnelPalletReEntryService";
	import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService";
	import _sMatureBalancePalletService from "@/services/FrozenProduction/MatureBalancePalletService";

	export default {
		components: {
			SSelectFruitState,
		},

		props: {
			pallet: null,
		},

		data() {
			return {
				palletNew: {},
				TypeCultive: 0,
				pallets: [],
				headers: [
					{text: "Acción", value: "delete"},
					{text: "ID", value: "MprID"},
					{text: "N° Jabas", value: "MptQuantityJaba"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
				],
				totalJabas: 0,
				dialogWeight: false
			}
		},

		watch: {
			pallet() {
				this.palletNew = this.pallet;
			}
		},

		methods: {
			calculateOldWeight(value)
			{
				if(value == ''){value = 0}
				console.log("que peso tengo",this.pallet.LlpWeight);
				this.palletNew.MbpWeightOld = this.pallet.LlpWeightOriginalNet;
				// this.palletNew.MbpWeightOld = parseFloat(this.palletNew.MbpWeightOld) - parseFloat(value);
			},



			CloseBalance() {
				this.$emit("CloseBalance");
			},


			AddBalance()
			{
				if(this.palletNew.MbpQuantityJaba == 0)
				{
					this.$refs.textNumberJabas.error("Ingrese número de Jabas");
					return;
				}
				if(this.palletNew.MbpWeightNew == 0){
					this.$refs.textMbpWeightNew.error("Ingrese el peso de la nueva parihuela");
					return;
				}
				if(this.palletNew.MbpWeightOld == 0){
					this.$refs.textMbpWeightOld.error("Ingrese el peso de la parihuela a particionada");
					return;
				}

				if(this.palletNew.MbpWeightOld <= 0){
					this.$refs.textMbpWeightOld.error("Error, en peso de Parihuela Particionada");
					return;
				}

				if(this.palletNew.FtsID == null){
					this.$fun.alert("Error, Selecione estado de fruta", "warning");
					return;
				}

				if(this.palletNew.TypePalletMate == null || this.palletNew.TypePalletMate == 0) {
					this.$fun.alert("Elija un tipo de pallet", "warning");
					return;
				}

				
				//samir
				console.log("guardar", this.palletNew);
				//return;


				this.$fun.alert("¿Seguro de particionar Pallet?", "question")
				.then(r => {
					if(r.value){
						// this.palletNew.MbpWeightNew = 
						this.palletNew.LlpOriginStage = 2;
						_sMatureBalancePalletService
						.save(this.palletNew, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Pallet Creado Correctamente", "success");
								this.CloseBalance();
							}
						})
					}
				})
				

				/* if(this.palletNew.MptWeightOld == 0)
				{
					this.$fun.alert("Ingrese peso del pallet");
					return;
				} */

				/* if(!this.ValidateWeightOld()){
					this.$fun.alert("Revise el peso ingreso por favor");
					return;
				} */
				
				
				/* let ReEntry = {
					MtpID : this.palletNew.MtpID,
					MptQuantityJaba : this.palletNew.MptQuantityJaba,
					MptWeight : this.palletNew.MptWeight,
					MptWeightOld : this.palletNew.MptWeightOld,
					FtsID : this.palletNew.FtsID,
					UsrCreateID : this.$fun.getUserID(),
				}
				_sMaturationTunnelPalletReEntryService.insert(ReEntry, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Jabas apiladas correctamente", "success");
						this.dialog = false;
						this.palletNew = {};
						this.Initialize();
					}
				}) */
			},
		},

		created () {
			
			console.log('informacion del pallet cargado', this.pallet);
			this.palletNew.LlpID = this.pallet.LlpID;
			this.palletNew.MbpQuantityJaba = 0;
			//this.palletNew.FtsID = 1005; // EL 1005 ES EL ID DEL ESTADO DE FRUTA MADURO
			this.palletNew.MtpID = this.pallet.MtpID;
			this.palletNew.SecStatus = 1;
			this.palletNew.UsrCreateID = this.$fun.getUserID();
			this.palletNew.UsrUpdateID = this.$fun.getUserID();
			this.TypeCultive = this.pallet.TypeCultive;
			
			this.palletNew.LlpWeightOriginalGross = this.pallet.LlpWeightOriginalGross
			this.palletNew.MbpWeightOld = this.pallet.LlpWeight;
		},
	}
</script>



			<!-- <v-row>
				<v-col cols="10">
					<v-data-table
						:headers="headers"
						:items="pallets"
						dense
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.delete="{ item }">
							<v-btn
								outlined
								color="error"
								x-small
								@click="deletePallet(item)"
							>
								ELIMINAR
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="2">
					
					<v-btn
						color="info"
						fab
						x-large
						dark
						@click="createWeight()"
					>
						<span class="white--text text-h4">{{totalJabas}}</span>
					</v-btn>
				</v-col>
			</v-row> -->

		<!-- <v-dialog
			v-model="dialogWeight"
			v-if="dialogWeight"
			persistent
			width="400"
		>
			<v-card>
				<s-toolbar
					label="Registrar Peso de Pallet"
					dark
					:color="'#BAB6B5'"
					close
					@close="dialogWeight = false"
					save
					@save="createPallet()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12">
							<s-text
								v-model="palletNew.MptWeight"
								decimal
								label="Peso Nuevo"
							>
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog> -->


			/* ValidateWeightOld()
			{
				let isvalid = false;
				let JabasReference = 48;
				let WeightReference = 1300;
				let weight = (WeightReference * (this.pallet.LlpQuantityJaba - this.palletNew.MptQuantityJaba))/JabasReference;
				let weightmax = weight + 100;
				let weightmin = weight - 100;

				if(this.palletNew.MptWeightOld < weightmax && this.palletNew.MptWeightOld > weightmin){
					isvalid = true;
				}
				return isvalid;
			}, */

			/* ValidateWeightNew(jabastotal)
			{
				let isvalid = false;
				let JabasReference = 48;
				let WeightReference = 1300;
				let weight = (WeightReference * jabastotal)/JabasReference;
				let weightmax = weight + 100;
				let weightmin = weight - 100;

				if(this.palletNew.MptWeight < weightmax && this.palletNew.MptWeight > weightmin){
					isvalid = true;
				}
				return isvalid;
			}, */


			/* Initialize() {
				this.totalJabas = 0;
				_sMaturationTunnelPalletService
				.reentrylist(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.pallets = resp.data;
						this.pallets.forEach(element => {
							this.totalJabas += element.MptQuantityJaba
						})
					}
				})
			}, */




						/* deletePallet(item)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						item.SecStatus = 0;
						item.UsrUpdateID = this.$fun.getUserID();
						this.totalJabas = this.totalJabas - item.MptQuantityJaba;
						_sMaturationTunnelPalletReEntryService.update(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Jabas eliminadas correctamente", "success");
								this.dialog = false;
								this.Initialize();
							}
						})
					}
				})
				
			},
 */
			/* createWeight()
			{
				this.dialogWeight = true;
			}, */

			/* createPallet()
			{
				if(this.palletNew.MptWeight == 0 )
				{
					this.$fun.alert("Ingrese el peso del pallet", "warning");
					return;
				}

				if(this.pallets.length > 0)
				{
					this.$fun.alert("¿Seguro de crear Pallet?", "question")
					.then(r => {
						if(r.value){
							let representativeLot = this.pallets[0];
							let totalJab = 0;
							let maxjaba = this.pallets[0].MptQuantityJaba;
							this.pallets.forEach(element => {
								if(element.MptQuantityJaba > maxjaba){
									representativeLot = element;
								}
							});
							this.pallets.forEach(element => {
								totalJab = totalJab + element.MptQuantityJaba;
							});
							representativeLot.MptWeight = this.palletNew.MptWeight;
							representativeLot.MptQuantityJaba = totalJab;

						

							_sMaturationTunnelPalletReEntryService
							.entry(representativeLot, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200){
									this.$fun.alert("Parihuela creada correctamente", "success");
									this.Initialize();
									
									
								}
							})
						}
					})
				}
			} */