<template>
  <div>
    <v-card class="mt-0 pt-0" lg="6">
      <!-- Inicio Campos de Alerta -->
     
    </v-card>
  </div>
</template>

<script>
import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
import _sGenParamService from "../../../services/General/ParameterService";
import qrCode from "vue-qr-generator";

export default {
  props: {
    ship: {
      type: Number,
      required: true,
    },
  },
  components: {
    qrCode,
  },
  data: () => ({
    headersGang: [
      { text: "Capataz", value: "FrmName", width: 250 },
      { text: "Cant. Jabas Cosechadas", value: "FrmQuantityReal" },
    ],

    headersWeighed: [
      { text: "Fecha/Hora", value: "WpbDate", width: 150 },
      { text: "Nro. Palet", value: "WpbNumberPallet", width: 80 },
      { text: "Cant. Jabas", value: "WpbJabasQuantity", width: 100 },
      { text: "Peso Jaba", value: "WpbWeightJaba", width: 100 },
      { text: "Peso Parihuela", value: "WpbWeightPallet", width: 100 },
      { text: "Peso Bruto", value: "WpbWeightGross", width: 150 },
      { text: "Peso Neto", value: "WpbWeightNet", width: 100 },
      {
        text: "Peso Prom. Jaba",
        value: "WpbWeightAverageJaba",
        width: 100,
      },
    ],

    items: {
      WpbID: 0,
      WpbJabasQuantity: 40,
      WpbWeightGross: 0,
      WpbWeightPallet: 37,
    },
    RcfID: 0,
    objNumberGuide: [],
    itemsGang: [],
    selectedWeighed: [],
    itemsNumberGuide: [],
    viewItemsAll: {},
    /* viewItemsGang: {}, */

    itemsWeighed: [],
    sumTotalJaba: 0,
    alert: false,
    RcfWeightProm: 0,
    clickEdit: 0,
    valParamMin: 0,
    valParamMax: 0,
    textQR: "Sin datos",
  }),

  methods: {
    //Cargar Parametros
    loadParameter() {
      _sGenParamService
        .search({ PrmName: "PesoPromedioJabaMax" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.valParamMax = resp.data.PrmValue;
          }
        });

      _sGenParamService
        .search({ PrmName: "PesoPromedioJabaMin" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.valParamMin = resp.data.PrmValue;
          }
        });

      _sGenParamService
        .search({ PrmName: "PesoJabaFrescos" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.items.WpbWeightJaba = resp.data.PrmValue;
          }
        });
    },

    //Limpair todo
    clearAll() {
      this.items.WpbID = 0;
      this.items.WpbJabasQuantity = 40;
      this.items.WpbWeightGross = 0;
      this.items.WpbWeightJaba = 1.7;
      this.items.WpbWeightPallet = 37;
      this.clickEdit = 0;
      this.getWeighedByBatch(this.objNumberGuide.RcfID);
    },

    //Seleccionar Fila de Pesada
    rowClickWeighed: function (item, row) {
      this.selectedWeighed = [item];
      console.log("this.selectedWeighed", this.selectedWeighed);
      this.sumTotalJaba = this.GetSumQuantityJabas();
      this.clickEdit = 0;
      //console.log("rowClickWeighed", this.selectedWeighed);

      /* this.selectedWeighed[0].WpbID.toString() +  */
      var cadTextQR =
        this.selectedWeighed[0].RcfID.toString() +
        ", " +
        this.selectedWeighed[0].WpbID.toString() +
        ", " +
        this.selectedWeighed[0].WpbJabasQuantity +
        ", " +
        this.selectedWeighed[0].WpbYuliano +
        ", LP-" +
        this.selectedWeighed[0].RcfID.toString() +
        ", Cant. Jabas: " +
        this.selectedWeighed[0].WpbJabasQuantity +
        ", PP. Jaba: " +
        this.selectedWeighed[0].WpbWeightAverageJaba +
        ", PN: " +
        this.selectedWeighed[0].WpbWeightNet +
        ", PB: " +
        this.selectedWeighed[0].WpbWeightGross +
        ", " +
        this.selectedWeighed[0].WpbDate +
        ", " +
        this.selectedWeighed[0].TypeDoorName;

      this.textQR = cadTextQR;
    },

    //Al seleccionar registro en el combo de Puerta, Recibimos el Id de la Puerta
    inputDoor(TypeDoor) {
      if (TypeDoor !== 0) {
        //Obtener los registros que estan siendo pesados en la puerta selecioanda
        //Si aun esta en proceso,
        if (this.itemsNumberGuide.length > 0) {
          _sWeighedByBatch
            .getDoor(
              { TypeDoor: TypeDoor, TypeShip: this.ship },
              this.$fun.getUserID()
            )
            .then((r) => {
              if (r.data.length > 0) {
                this.RcfID = r.data[0].RcfID; //Seleccionamos por Defecto la Guia de Cosecha
              } else {
                this.RcfID = 0;
              }
            });
        }
      }
    },

    //Al seleccionar registro en el combo Guia de Cosecha
    inputNumberGuide(obj) {
      if (obj !== null || obj !== undefined) {
        this.viewItemsAll = this.objNumberGuide;
        console.log(this.viewItemsAll);
        this.alert = true; //Mostramos la alerta
        this.getWeighedByBatch(obj.RcfID);
      }
    },

    //Obtener Pesadas
    getWeighedByBatch(RcfID) {
      _sWeighedByBatch
        .getWeighedBatch(
          {
            RcfID: RcfID, //this.viewItemsGang.RcfID,
            RcfGngID: 0,
            TypeDoor: this.items.TypeDoor,
          },
          this.$fun.getUserID()
        )
        .then((r) => {
          this.itemsWeighed = r.data; //Registros de Pesada
          this.itemsWeighed.forEach((element) => {
            element.WpbDate = this.$moment(element.WpbDate).format(
              this.$const.FormatDateTimeDB
            );
          });
        });
    },

    //***************************** CALCULOS ******************************************* */

    //Calcular el peso promedio de Jaba
    GetWeightAverageJaba() {
      if (this.items !== undefined) {
        var WeightAverageJaba = 0;
        WeightAverageJaba =
          this.GetWeightNetPallet / this.items.WpbJabasQuantity;
      }

      return WeightAverageJaba;
    },

    //Sumar todas la Cantidad de jabas de la Grilla, Retorna la suma
    GetSumQuantityJabas() {
      var QuantityJabas = 0;
      this.itemsWeighed.forEach((element) => {
        QuantityJabas = QuantityJabas + parseInt(element.WpbJabasQuantity);
      });

      return QuantityJabas;
    },

    //Calcular las Jabas restantes que aun faltan
    isDifferenceJabas(Amount) {
      var isValidated = true;
      var restante = this.viewItemsAll.QuantityJabas - this.sumTotalJaba;

      if (restante < Amount) {
        this.$fun.alert(
          "Esta superando la cantidad de jabas recepcionadas",
          "warning"
        );
        isValidated = false;
        return;
      }
      return isValidated;
    },

    //***************************** FIN CALCULOS ******************************************* */

    //*****************************  GUARDADO Y VALIDACIONES ******************************************* */

    //Validacion General
    isValidatedAll() {
      var isValidated = true;

      if (
        this.objNumberGuide.RcfID == null ||
        this.objNumberGuide.RcfID == undefined
      ) {
        this.$fun.alert("Seleccione un Nro. Guia de cosecha", "warning");
        return;
      }

      //Validamos las jabas restantes
      if (!this.isDifferenceJabas(this.items.WpbJabasQuantity)) {
        return;
      }

      if (
        this.items.WpbJabasQuantity <= 0 ||
        this.items.WpbJabasQuantity.length == 0
      ) {
        this.$refs.focusJabasQuantity.error(
          "Debe ingresar Cantidad de Jabas valido"
        );
        isValidated = false;
        return;
      }

      if (this.items.TypeDoor == undefined) {
        this.$fun.alert("Seleccione una puerta..", "warning");
        isValidated = false;
        return;
      }

      if (
        this.items.WpbWeightGross <= 0 ||
        this.items.WpbWeightGross.length == 0
      ) {
        this.$refs.focusWeightGross.error("Debe ingresar un Peso Bruto válido");
        isValidated = false;
        return;
      }

      if (
        this.items.WpbWeightJaba <= 0 ||
        this.items.WpbWeightJaba.length == 0 ||
        this.items.WpbWeightJaba == undefined
      ) {
        this.$fun.alert(
          "Debe Configurar el Precio por Jaba, Contacte con el Area de Producción",
          "warning"
        );
        isValidated = false;
        return;
      }

      if (
        this.items.WpbWeightPallet <= 0 ||
        this.items.WpbWeightPallet.length == 0
      ) {
        this.$refs.focusWeightPallet.error(
          "Debe ingresar un Peso de Parihuela válido"
        );
        isValidated = false;
        return;
      }

      if (this.GetWeightNetPallet <= 0 || this.GetWeightNetPallet.length == 0) {
        this.$fun.alert("El Peso neto Calculado es incorrecto", "warning");
        isValidated = false;
        return;
      }

      //Margenes de Peso Promedio de Jaba
      var marginPriceAverage = {
        Min: this.valParamMin,
        Max: this.valParamMax,
      };
      var WeightAverage = this.GetWeightAverageJaba();

      if (
        WeightAverage < marginPriceAverage.Min ||
        WeightAverage > marginPriceAverage.Max
      ) {
        this.$fun.alert(
          "El Peso Promedio Jaba Calculado, No esta dentro del rango establecido ==> Min = " +
            marginPriceAverage.Min +
            ", Max = " +
            marginPriceAverage.Max +
            ", Peso Promedio Calculado = " +
            WeightAverage,
          "warning"
        );
        isValidated = false;
        return;
      }

      return isValidated;
    },

    //Grabar Pesada
    Grabar() {
      if (!this.isValidatedAll()) {
        return;
      }

      this.items.RcfID = this.objNumberGuide.RcfID;
      (this.items.RcfGngID = null), (this.items.SecStatus = 1);
      this.items.UsrCreateID = this.$fun.getUserID();
      this.items.WpbWeightNet = this.GetWeightNetPallet;
      this.items.WpbWeightAverageJaba = this.GetWeightAverageJaba();
      this.items.TypeShip = this.ship;

      console.log("save", this.items);

      _sWeighedByBatch.save(this.items, this.$fun.getUserID()).then((r) => {
        if (r.status == 200) {
          this.$fun.alert("Registrado correctamente", "success");
          this.getWeighedByBatch(this.objNumberGuide.RcfID);
          this.clearAll();
          this.$refs.focusWeightGross.focus();
        }
      });
    },

    //Editar una pesada
    edit() {
      if (this.selectedWeighed.length > 0) {
        this.items = this.selectedWeighed[0];
        this.items.WpbID = this.selectedWeighed[0].WpbID;
        //Disminuimos a la Cantidad Total la cantidad que vamos a editar
        if (this.clickEdit == 0) {
          this.sumTotalJaba =
            this.sumTotalJaba - this.selectedWeighed[0].WpbJabasQuantity;
          this.clickEdit += 1;
        }
      } else {
        this.$fun.alert("No existen registros", "warning");
      }
    },

    //Eliminar una pesada
    remove() {
      if (this.itemsWeighed.length > 0) {
        var items = this.itemsWeighed[0];

        console.log("remove", items);

        items.SecStatus = 0;
        items.UsrCreateID = this.$fun.getUserID();

        this.$fun
          .alert("Esta seguro de eliminar el registro ? ", "question")
          .then((val) => {
            if (val.value) {
              _sWeighedByBatch.save(items, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                  this.$fun.alert("Eliminado correctamente", "success");
                  this.getWeighedByBatch(items.RcfID);
                }
              });
            }
          });
      }
    },

    printQR() {
      //console.log("printQR", this.selectedWeighed[0], this.RcfGngID);
      var img = document.querySelector("img");

      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(
        "<html><head><scri" +
          "pt>function step1(){\n" +
          "setTimeout('step2()', 2);}\n" +
          "function step2(){window.print();window.close()}\n" +
          "</scri" +
          "pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
          "<div class='col col-12 justify-center'>"
      );

      pwa.document.write(
        "<div style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;' src='" +
          img.currentSrc +
          "' /></div>"
      );
      pwa.document.write("<br><br><div ><table border='0'>");

      pwa.document.write(
        "<tr><td>Pallet Nro: </td><td>" +
          this.selectedWeighed[0].WpbYuliano +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Lote Produccion: </td><td>" +
          this.viewItemsAll.RcfID +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Productor: </td><td>" +
          this.viewItemsAll.PrdCardName +
          " </td></tr>"
      );
      /* pwa.document.write(
					"<tr><td>Lote: </td><td>" +
						this.objForeman.RcfGngID.FltName +
						" </td></tr>",
				); */
      pwa.document.write(
        "<tr><td>Fundo: </td><td>" + this.viewItemsAll.FltName + " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Cultivo: " +
          this.viewItemsAll.TypeCultiveName +
          " </td><td> Variedad: " +
          this.viewItemsAll.VrtName +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Tipo Cultivo: </td><td>" +
          this.viewItemsAll.TypeCropName +
          " </td></tr>"
      );

      pwa.document.write(
        "<tr><td>Cant. Jabas: </td><td>" +
          this.selectedWeighed[0].WpbJabasQuantity +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Peso Promedio Jaba: </td><td>" +
          this.selectedWeighed[0].WpbWeightAverageJaba +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Peso Bruto: " +
          this.selectedWeighed[0].WpbWeightGross +
          " </td><td> Peso Neto: " +
          this.selectedWeighed[0].WpbWeightNet +
          " </td></tr>"
      );
      pwa.document.write(
        "<tr><td>Puerta: </td><td>" +
          this.selectedWeighed[0].TypeDoorName +
          " </td></tr>"
      );

      /* pwa.document.write(
					"<tr><td>Certificaciones: </td><td>" +
						this.objForeman.RcfGngID.TcDescription +
						" </td></tr>",
				); */

      pwa.document.write("</table></div>");

      pwa.document.write("</div></body></html>");
      pwa.document.close();
    },
  },

  watch: {
    itemsWeighed() {
      this.sumTotalJaba = this.GetSumQuantityJabas();
    },
    GetWeightNetPallet() {
      this.RcfWeightProm = this.GetWeightAverageJaba();
    },
  },

  computed: {
    //Calcular el peso Neto
    GetWeightNetPallet() {
      if (this.items !== undefined) {
        var QuantityJabas = 0,
          WeightGross = 0,
          WeightPallet = 0,
          WeightJaba = 0,
          WeightNet = 0;
        QuantityJabas = this.items.WpbJabasQuantity;
        WeightGross = this.items.WpbWeightGross;
        WeightPallet = this.items.WpbWeightPallet;
        WeightJaba = this.items.WpbWeightJaba;

        WeightNet = WeightGross - WeightPallet - WeightJaba * QuantityJabas;
      }

      return WeightNet;
    },
  },

  created() {
    //Cargar las Guia  de cosecha
    _sWeighedByBatch
      .getHarvestGuide(
        { xPrint: 0, TypeShip: this.ship },
        this.$fun.getUserID()
      )
      .then((r) => {
        if (r.data.length > 0) {
          this.itemsNumberGuide = r.data;
          console.log("Guias de Cosecha", this.itemsNumberGuide);
        }
      });

    this.loadParameter();
  },
};
</script>
