import Service from "../Service";
const resource = "/maturebalancepallet/"

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID }
        });
    },

    listpalletpartition(LlpID, requestID) {
        return Service.post(resource + "listpalletpartition", {}, {
            params: { LlpID, requestID }
        });
    },

    joinpallets(obj, requestID) {
        return Service.post(resource + "joinpallets", obj, {
            params: { requestID }
        })
    }
}